<template>
  <div class="footer know-more">
    <div class="cover-txt">
      <p class="heading">
        <span v-appToggleDiv="{id:'disclaimer'}">
          Disclaimers
          <sup>+</sup>
          <i class="arrowbtn"></i>
        </span>
      </p>
      <div id="disclaimer" class="toggle">
        <template v-if="!isFromLicPrequotes">
          <p>
            <sup>+</sup>On the basis of your profile
          </p>
          <p v-if="!isNri">
            <template v-if="isFromSpecialPrequotes"> <sup>+</sup>Rs. 485/month is Starting price for a 1 crore term life insurance for an 18 year-old Female, non-smoker, with no pre-existing diseases, cover upto 36 years of age . For more details on risk factors, terms and conditions, please read the sales brochure carefully before concluding a sale.</template>
            <template v-else> <sup>+</sup>Rs. 473/month is Starting price for a 1 crore term life insurance for an 18 year-old Female, non-smoker, with no pre-existing diseases, cover upto 36 years of age rounded off to nearest 10. For more details on risk factors, terms and conditions, please read the sales brochure carefully before concluding a sale.</template>
          </p>
          <p v-if="isNri">
            <sup>+</sup>Rs. 750/month is Starting price for a 2 crore term life insurance for an 18 year-old Female, non-smoker, with no pre-existing diseases, cover upto 38 years of age.
          </p>
          <p>
            <template v-if="isFromSpecialPrequotes"> <sup>+</sup>Rs. 544/month is the starting price for a 1 crore term life insurance for an 24 year-old female, non-smoker, with no pre-existing diseases, cover upto 54 years of age.</template>
            <template v-else><sup>+</sup>Rs. 601/month is the starting price for a 1 crore term life insurance for an 24 year-old female, non-smoker, with no pre-existing diseases, cover upto 54 years of age.</template>
          </p>
          <p>
            <template v-if="isFromSpecialPrequotes"> <sup>+</sup>Rs. 2,125/month is the starting price for a 1 crore term life insurance for an 44 year-old female, non-smoker, with no pre-existing diseases, cover upto 74 years of age.</template>
            <template v-else> <sup>+</sup>Rs. 1,981/month is the starting price for a 1 crore term life insurance for an 44 year-old female, non-smoker, with no pre-existing diseases, cover upto 74 years of age.</template>
          </p>
          <template v-if="isFromSpecialPrequotes">
              <p>
                <sup>##</sup>Savings of upto 20% are based on the comparison between the premium  for a 33-year old salaried male and female, non-smoker, with no pre-existing diseases with cover upto 60 years of age for Rs. 1 crore term life insurance cover.
              </p>
          </template>
        </template>
        <p>
          Prices offered by the insurer are as per the IRDAI approved insurance plans | #All savings and online discounts are provided by insurers as per IRDAI approved insurance plans | Standard Terms and Conditions Apply |
          <sup>**</sup>Tax Benefits are subject to changes in tax laws.| Policybazaar Insurance Brokers Private Limited
        </p>
        <p>
          <sup>*</sup>We will respond in the first instance within 30 minutes of the customers contacting us. 30-minute claim support service is for the purpose of giving reasonable assistance to the policyholder in pursuance of the claim. Settlement of claim (including cashless claim) is the responsibility of the insurer as per policy terms and conditions. The 30-minute claim support is subject to our operations not being impacted by a system failure or force majeure event or for reasons beyond our control. For further details, 24x7 Claims Support Helpline can be reached out at 1800-258-5881
        </p>
        <p>
          <sup>~</sup>Source: Google Review Rating available on:-
          <a target="_blank" href="http://bit.ly/3J20bXZ">http://bit.ly/3J20bXZ</a>
        </p>
        <p>For more details on risk factors, terms and conditions, please read the sales brochure carefully before concluding a sale.</p>
        <p>Policybazaar Insurance Brokers Private Limited | CIN: U74999HR2014PTC053454 | Registered Office - Plot No.119, Sector - 44, Gurgaon, Haryana – 122001 | Registration No. 742, Valid till 09/06/2027, License category- Composite Broker | Visitors are hereby informed that their information submitted on the website may be shared with insurers. Product information is authentic and solely based on the information received from the insurers.</p>
        <p>© Copyright 2008-2024 policybazaar.com. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Prop } from 'vue';
import appToggleDiv from "@/common/directives/appToggleDiv";

export default defineComponent({
  name: 'WomenFooterComponent',
  props: {
    msg: {
      type: String,
      required: false,
    },
    isNri:{
      type:Boolean,
      required: false,
    },
    isFromLicPrequotes:{
      type: Boolean,
      required: false,
    },
    isFromSpecialPrequotes:{
      type: Boolean,
      required: false,
    }
  },
directives: {
      appToggleDiv,
    }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  width: 1200px;
  float: left;
  text-align: center;
  font-size: 10px;
  margin-top: 25px;
  padding-bottom: 60px;
  margin: 0 auto;
  float: none;
}
#disclaimer p a {
  color: var(--footertext);
}
.footer p {
  margin: 0;
  line-height: 19px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  color: var(--footertext);
}
.footer p a {
  color: #157bfb;
  text-decoration: none;
  font-weight: 400;
}
.know-more .cover-txt {
  width: 100%;
  float: left;
  background: transparent;
  padding: 20px;
  margin: 50px 0 20px;
  font-size: 10px;
  color: var(--comparetxtcolor);
}
.know-more .headings {
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  padding: 8px 0;
  font-size: 14px;
  border-radius: 8px 8px 0 0;
  background: var(--radio_button_bg);
  width: 285px;
  color: #fff;
  position: relative;
}
.heading .arrowbtn {
  display: inline-block;
  position: relative;
  font-style: normal;
  float: right;
}
.heading .arrowbtn:after {
  position: absolute;
  pointer-events: none;
  content: "";
  top: 5px;
  right: 0;
  width: 8px;
  height: 8px;
  border-width: 2px;
  border-style: solid;
  border-bottom: transparent;
  border-left: transparent;
  transform: rotate(134deg);
}

.heading .iconChange .arrowbtn:after {
  transform: rotate(-45deg);
}

.footer.know-more .cover-txt {
  width: 100%;
  padding: 0;
  margin: 30px 0 100px;
  background: var(--white);
  float: left;
  box-shadow: 0 3px 12px #00000029;
  border-radius: 4px;
}

.footer.know-more .heading span {
  background: var(--white);
  padding: 14px 20px;
  font-weight: 400;
  color: var(--footertext);
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  float: left;
  border-radius: 4px;
  text-align: left;
  display: block;
}

.right-section.nrivalue
  .contents_div_desk
  .online_discount.nriDiscount.PrequoteTypeZC {
  width: 100% !important;
  position: relative;
  left: auto;
  text-align: center;
}

.know-more .headings:after {
  position: absolute;
  background: var(--borderline);
  height: 1px;
  width: 1190px;
  content: "";
  top: 98%;
  left: 0;
}
.know-more .toggle {
  display: none;
  padding: 20px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-color: transparent;
  text-align: left;
}
.know-more .toggle.show {
  display: block;
  background: var(--white);
}
.know-more .toggle h3 {
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
}
.know-more .toggle ul {
  padding-bottom: 20px;
}
.know-more .toggle h3 {
  font-size: 14px;
  font-weight: 600;
}
.know-more ul li {
  font-size: 11px;
  list-style: disc;
  padding-bottom: 0;
  margin-left: 20px;
  line-height: 18px;
  font-weight: 400;
}

@media all and (min-width: 320px) and (max-width: 1024px) {
  .footer {
    width: 92%;
  }
  .heading .arrowbtn {
    top: 3px;
    right: 0;
    width: 6px;
    height: 6px;
  }
  .footer p {
    font-size: 10px;
    margin-bottom: 8px;
    line-height: 17px;
  }
}
</style>
